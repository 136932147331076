import React from 'react'
import '../css/ieModal.css'







const IEModal = ({data}) => {

  return(
    <div className="IEmodal"><div className="colourCover"><div className="IEmodal_inner"><h1>Wyeld Swim</h1><h2>Oh no, we’re sorry!</h2><br/><p>Our website doesn’t work in Internet Explorer.</p>
    <p>In August 2021 Microsoft will stop supporting Internet Explorer, the browser will be replaced with Microsoft Edge.</p>
    <p>Please head over to Edge, Chrome, Safari or any other modern browser to view this site. </p></div></div></div>

)
}
// function mapStateToProps(state) {
//   return {
//     HorizontalNumbers: state.HorizontalNumbers,
//     HorizontalData:state.HorizontalData,
//     scrollPos:state.scrollPos,
//     sectionNumber:state.sectionNumber
//   };
// }
// export default connect(mapStateToProps)(TemplateWrapper);
// export default TemplateWrapper
export default IEModal
//
// export const pageQuery = graphql`
//   query ErrorQuery {
//     strapiHomepage {
//         title
//         social_image {
//           publicURL
//         }
//       }
//
// }
//
// `
